import initialState from '../initialState';

const thisInitialState = {
    menu_switcher: initialState.menu_switcher
}

export function switchMenuReducer(state = thisInitialState, action) {
    switch (action.type) {
        case 'SWITCH_MENU':
            state=!state
            return state;
        default:
            return state;
    }
}