import { combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore'
import { switchMenuReducer } from './switchMenuReducer';
import { switchConsoleReducer } from './switchConsoleReducer';
import { dateSelectorReducer } from './dateSelectorReducer';
import { pageSelectorReducer } from './pageSelectorReducer';
import { switchRecentReadingsReducer } from './switchRecentReadingsReducer';
import { alertBoxReducer } from './alertBoxReducer'
const root = combineReducers({
    menu_switcher: switchMenuReducer,
    console_switcher: switchConsoleReducer,
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    dateSelector: dateSelectorReducer,
    pageSelector: pageSelectorReducer,
    switch_recent_readings: switchRecentReadingsReducer,
    alert_box: alertBoxReducer,
});

export default root;
