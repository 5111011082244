import React from "react";
import { Image } from "react-bootstrap";
import { switchConsole } from "../../store/actions/switchConsole";
import { connect } from 'react-redux';
//
function Logo({ switchConsole }) {
    //
    return (
        <div className="logo" onClick={switchConsole}>
            <Image alt="logo aurora" src={require(`../../assets/images/logo-white.svg`).default} />
        </div>
    );
}

const mapStateToProps = state => ({
})
const mapDispatchToProps = (dispatch) => {
    return {
        switchConsole: (value) => dispatch(switchConsole())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Logo);
