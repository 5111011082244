import { BrowserRouter, Switch } from 'react-router-dom';
//UTILS
import PublicRouter from './utils/public_router';
import Page from './components/page'
//COMPONENTS
import Index from './pages';

function App() {
  const globalTitle = 'Aurora - ';
  //
  return (
    <>
      <BrowserRouter>
        <Switch>
          {/* O Root -> Landing Page*/}
          <PublicRouter path="/" component={Page({ component: Index, label: globalTitle, title: 'Home' })} />
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
