import initialState from '../initialState';

const thisInitialState = {
    console_switcher: initialState.console_switcher
}

export function switchConsoleReducer(state = thisInitialState, action) {
    switch (action.type) {
        case 'SWITCH_CONSOLE':
            state=!state
            return state;
        default:
            return state;
    }
}