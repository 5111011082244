import React, { useEffect, useRef } from "react";
import * as d3 from 'd3';
import moment from "moment";
//REDUX
import { connect } from 'react-redux';
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
//COMPONENTS
import LoadChart from '../loadChart';
//
function WeeklyStrip({ Alexa, userID, targetDate, weeklyReadings, weekLabels, weekStart }) {
    console.warn('render chart: ', 'weekly-readings-heatmap');
    console.log('weekly-readings-heatmap', weeklyReadings);
    console.log('weekly-readings-heatmap targetDate', targetDate);
    //
    const d3Chart = useRef();
    //
    //color scale
    const glucoseColorRange = d3.scaleLinear()
        .domain([
            0, 55, 68,
            70, 120, 150,
            155, 180, 250, 1000
        ])
        .range([
            '#D6A808', '#F9C307', '#F9C307',
            '#A5E0E2', '#2FB3BE', '#098B9D',
            '#EE9899', '#E04445', '#BA150F', '#9B1011',
        ]);
    function handleMouseOver(d, p) {
        handleMouseOut();
        if (p.data) {
            const sgv = !p.data.hasHigh && p.data.hasLow ? p.data.lowestSgv : p.data.highestSgv;
            //const sgvText = p.data.hasLow ? p.data.highestSgv + " / " + p.data.lowestSgv : p.data.highestSgv;
            d3.select("#d3demoblocked").insert("div", ":first-child")
                .attr("id", "chartTooltipRect")
                .attr("class", "shadow")
                .style("left", (d.pageX - 25) + "px")
                .style("top", (d.pageY - 20) + "px")
                .style('background-color', `${glucoseColorRange(sgv)}`)
                .style('color', `${glucoseColorRange(sgv)}`)
                .append('span').text(sgv);
        }

    }
    function handleMouseOut() {
        if (d3.select("#d3demoblocked").selectAll("#chartTooltipRect"))
            d3.select("#d3demoblocked").selectAll("#chartTooltipRect").remove();
    }
    //
    // CREATE DATE DIFFERENCE
    const getDateFromOther = (originalDate, daysDifference) => {
        let newDate = new Date(originalDate.getFullYear(), originalDate.getMonth(), originalDate.getDate() + daysDifference, 0, 0, 0);
        return newDate;
    }
    //
    //DRAW CHART
    function drawChart() {
        console.warn('weekly-readings-heatmap drawChart: ', 'executed');
        const chartData = weeklyReadings || [];
        if (chartData) {
            //
            d3.select(d3Chart.current).selectAll("*").remove();
            //
            //SET DATES
            // quando é a segunda da semana pedida?
            const lastMonday = getDateFromOther(weekStart, 0);
            //
            // ROWS AND COLS SIZE
            const rows = 7; //weekdays
            const columns = 24; //hours
            // SET WIDTH AND HEIGHT OF CHART
            const width = 640;
            const height = (width / columns) * rows;
            // CREATE CHART DATE LIST
            let hoursInWeek = [];
            let cellTime = (new Date(lastMonday.getTime()));
            cellTime.setHours(0, 0, 0, 0);
            // CREATE CELL MARGIN AND CELL SIZE
            const gap = 2;
            const cellWidth = ((width - 80) / columns) - gap;
            //
            // Set up chart
            const svg = d3.select(d3Chart.current)
                .attr('width', width)
                .attr('height', height)
                .append('g')
                .attr("id", "#svg-content")
                .attr('transform', 'translate(50,0)');

            // retorna um d3.map das leituras agrupadas por hora,
            // já com cálculos de média, sgv máximo e svg mínimo
            let groupedByHour = d3.rollup(chartData,
                v => {
                    let obj = {};

                    obj.totalReadings = v.length;
                    obj.averageSgv = Math.round(d3.mean(v, d => d.sgv));
                    obj.highestSgv = d3.max(v, d => d.sgv);
                    obj.lowestSgv = d3.min(v, d => d.sgv);
                    obj.hasHigh = obj.highestSgv > 150 ? true : false;
                    obj.hasLow = obj.lowestSgv < 70 ? true : false;

                    return obj;
                },
                d => {
                    let date = new Date(d.date);
                    date.setHours(date.getHours(), 0, 0, 0);

                    return date.getTime();
                }
            );

            for (var i = 0; i < rows * columns; i++) {
                hoursInWeek.push({ date: cellTime.getTime(), data: groupedByHour.get(cellTime.getTime()) });
                // aumenta o tempo em uma hora pra cada loop
                cellTime.setHours(cellTime.getHours() + 1);
            }
            //
            //x axis scale
            let x = d3.scaleBand()
                .range([0, width - 80])
                .domain(hoursInWeek.map((d) => { return new Date(d.date).getHours() }));// aqui entra o horário local
            let xAxis = d3.axisBottom(x).tickFormat(d => `${d}h`)
            svg.append('g')
                .attr("class", "axisText axisX")
                .attr('transform', 'translate(0,' + (height - 27) + ')')
                .call(xAxis);
            //
            //x axis scale
            let y = d3.scaleBand()
                .range([0, height - 25])
                .domain(hoursInWeek.map((d) => { return new Date(d.date).getDay() }));// aqui é o horário local
            let yAxis = d3.axisLeft(y).ticks(7).tickFormat(d => `${weekLabels[d].slice(0, 1)}`)
            svg.append('g')
                .attr("class", "axisText axisY")
                .attr('transform', 'translate(0,0)')
                .call(yAxis);
            // cria os quadradinhos
            let rectGroups = svg.selectAll(".cell")
                .data(hoursInWeek)
                .enter()
                .append("g")
                .attr('class', (d) => {
                    let classNames = 'cell';
                    let cellObj = groupedByHour.get(d.date);
                    if (cellObj && cellObj.hasHigh) {
                        classNames += ' hourHigh';
                    }
                    if (cellObj && cellObj.hasLow) {
                        classNames += ' hourLow';
                    }
                    return classNames
                })
                .attr('transform', (d, i) => {
                    let row = Math.floor(i / columns);
                    let col = i - (row * columns);
                    let xPos = col * (cellWidth + gap);
                    let yPos = row * (cellWidth + gap);
                    //
                    return 'translate(' + xPos + ',' + yPos + ')'
                })
            //
            rectGroups.append("rect")
                .attr("width", cellWidth)
                .attr("height", cellWidth)
                .attr("rx", 3)
                .attr("ry", 3)
                .attr("date", d => new Date(d.date).toString())
                .attr("fill", (d, i) => {
                    let color = '#E0E0DB';
                    let cellObj = groupedByHour.get(d.date);
                    if (cellObj && cellObj.highestSgv > 70) {
                        color = glucoseColorRange(cellObj.highestSgv)
                    } else if (cellObj) {
                        color = glucoseColorRange(cellObj.lowestSgv)
                    }
                    return color;
                })
            rectGroups.on("mouseover", handleMouseOver)
            rectGroups.on("mousemove", handleMouseOver)
            rectGroups.on("mouseout", handleMouseOut);
            //
            rectGroups.append("circle")
                .attr("date", d => new Date(d.date).toString())
                .attr("r", 5)
                .attr("cx", cellWidth / 2)
                .attr("cy", cellWidth / 2)
                .attr("fill", (d) => {
                    let color = 'transparent';
                    let cellObj = groupedByHour.get(d.date);
                    if (cellObj && cellObj.highestSgv > 70 && cellObj.hasLow) { color = glucoseColorRange(cellObj.lowestSgv) };
                    return color;
                })
        }
    }
    //
    //send weekly readings useEffect
    useEffect(() => {
        if (weeklyReadings) {
            weeklyReadings.slice().sort((a, b) => { return b.sgv - a.sgv; });
            if (Alexa) {
                let newArray = weeklyReadings.map((item) => {
                    return {
                        sgv: item.sgv,
                        date: item.date,
                        direction: item.direction,
                    }
                })
                newArray = newArray.sort((a, b) => { return b.sgv - a.sgv; });
                let obj = {
                    request_type: 'weekly_readings',
                    user: userID,
                    readings: newArray,
                };
                Alexa.skill.sendMessage({ ...obj }, (r) => {
                    console.log('weekly_readings sendToAlexa');
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [weeklyReadings]);
    //
    //Draw Chart useEffect
    useEffect(() => {
        drawChart();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [weeklyReadings, targetDate]);
    //
    return (
        <>
            <LoadChart data={weeklyReadings} trigger={targetDate} onChange={drawChart}>
                <div id='d3demoblocked' className="weeklyd3chart">
                    <svg ref={d3Chart}></svg>
                </div>
            </LoadChart>
        </>
    );
}
const mapStateToProps = state => ({
    weeklyReadings: state.firestore.ordered.weeklyReadings,
    targetDate: state.dateSelector.targetDate,
    weekStart: state.dateSelector.weekStart,
    weekEnd: state.dateSelector.weekEnd,
})
const mapDispatchToProps = (dispatch) => {
    return {
    }
}
export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    firestoreConnect(props => {
        const id = props.userID;
        const weekEnd = props.weekEnd;
        const weekStart = props.weekStart;
        console.warn('weekly-readings-heatmap firestoreConnect: ', 'executed');
        return [
            {
                collection: 'readings',
                where: [
                    ['userID', '==', id],
                    ["date", "<=", moment(weekEnd).valueOf()],
                    ["date", ">=", moment(weekStart).valueOf()]
                ],
                orderBy: ['date', 'asc'],
                storeAs: 'weeklyReadings',
            }
        ];
    })
)(WeeklyStrip);
