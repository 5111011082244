const targetStartDate = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate(),
    0,
    0,
    0
);
const thisInitialState = {
    targetDate: targetStartDate,
    weekStart: new Date(
        targetStartDate.getFullYear(),
        targetStartDate.getMonth(),
        targetStartDate.getDate() - (targetStartDate.getDay()),
        0,
        0,
        0
    ),
    weekEnd: new Date(
        targetStartDate.getFullYear(),
        targetStartDate.getMonth(),
        targetStartDate.getDate() + (6 - targetStartDate.getDay()),
        23,
        59,
        59
    ),
}

export function dateSelectorReducer(state = thisInitialState, action) {
    switch (action.type) {
        case 'NEW_TARGET_DATE':
            let targetDate = action.targetDate;
            let weekStart = new Date(
                targetDate.getFullYear(),
                targetDate.getMonth(),
                targetDate.getDate() - (targetDate.getDay()),
                0,
                0,
                0
            );
            let weekEnd = new Date(
                targetDate.getFullYear(),
                targetDate.getMonth(),
                targetDate.getDate() + (6 - targetDate.getDay()),
                23,
                59,
                59
            );
            return {
                targetDate: targetDate,
                weekStart: weekStart,
                weekEnd: weekEnd
            };
        default:
            return state;
    }
}