import React, { useRef, useState, useEffect } from "react";
import { connect } from 'react-redux';
import { firebaseSaveEvent } from "../utils/firebaseQueries";
import Splash from './splash';
import RecentReadings from './recentReadings';
import WeeklyReadings from "./weeklyReadings";
import CalendarSelector from './calendar';
import { dateSelector } from '../store/actions/dateSelector';
import { pageSelector } from '../store/actions/pageSelector';
import { alertBox } from '../store/actions/alertBox';
import Menu from '../components/menu';
import SideBar from '../components/sideBar';
//
//ALEXA EVENT LABELS
const SAVE_EVENT = 'save_event';
const SET_EVALUATION = 'set_evaluation';
//
function Index({ console_switcher, menu_switcher, alertBox, dateSelector, pageSelector, currentPage, RECENT_READINGS, WEEKLY_READINGS, CALENDAR }) {
    //
    //ALEXA
    const AlexaComm = useRef();
    const [Alexa, setAlexa] = useState(null);
    const [alexaRequest, setAlexaRequest] = useState({});
    const [consoleAlexa, setConsoleAlexa] = useState({});
    const [userID, setUserID] = useState('');

    const initialise = async () => {
        let errorObj;
        let response;

        try {
            response = await window.Alexa.create();
            AlexaComm.current = response.alexa;
            console.log('create alexa response: ', JSON.stringify(response));
        } catch (error) {
            errorObj = error;
            console.error('create alexa error: ', JSON.stringify(errorObj));
        }

        setAlexa(AlexaComm.current);

        if (AlexaComm.current && response.message['user']) {
            setUserID(response.message['user']);
            pageSelector(RECENT_READINGS);
            setConsoleAlexa(response);
        } else {
            setConsoleAlexa('Alexa Client: ' + JSON.stringify(errorObj));
            setUserID('carolx');
            pageSelector(RECENT_READINGS);
        }
    }
    //
    //INITIALISE ALEXA
    useEffect(() => {
        dateSelector(new Date(
            new Date().getUTCFullYear(),
            new Date().getUTCMonth(),
            new Date().getUTCDate(),
            0,
            0,
            0
        ));
        //
        //Alexa
        initialise();
    }, []);
    //
    //ALEXA RESPONSE CALLBACK
    if (Alexa) {
        Alexa.skill.onMessage(function (sendResponse) {
            console.log('alexa onMessage response: ', JSON.stringify(sendResponse));
            setAlexaRequest(sendResponse);
            setConsoleAlexa(sendResponse);
        });
    }
    useEffect(() => {
        if (alexaRequest['request'])
            console.log('alexa response: ', JSON.stringify(alexaRequest));
        switch (alexaRequest['request']) {
            //
            //PAGES
            case RECENT_READINGS:
                pageSelector(RECENT_READINGS);
                break;
            case WEEKLY_READINGS:
                setConsoleAlexa(alexaRequest);
                dateSelector(new Date(parseInt(alexaRequest['date'])));
                pageSelector(WEEKLY_READINGS);
                break;
            case CALENDAR:
                pageSelector(CALENDAR);
                break;
            //
            //EVALUATION
            case SET_EVALUATION:
                setConsoleAlexa('Set Evaluation: ' + JSON.stringify(alexaRequest));
                alertBox({
                    text: alexaRequest['text'],
                    icon: alexaRequest['icon'],
                });
                break;
            //
            //EVENTS
            case SAVE_EVENT:
                firebaseSaveEvent({ ...alexaRequest, userID: userID })
                    .then(() => {
                        Alexa.skill.sendMessage({
                            request_type: SAVE_EVENT,
                            type: alexaRequest['type'],
                            result: 'success'
                        }, () => { setConsoleAlexa(SAVE_EVENT); });
                        return true;
                    }).catch((error) => {
                        Alexa.skill.sendMessage({
                            request_type: SAVE_EVENT,
                            description: alexaRequest['description'],
                            type: alexaRequest['type'],
                            result: 'error'
                        }, () => { setConsoleAlexa(error); });
                        return false;
                    });
                break;
            default:
                break;
        }
    }, [alexaRequest]);
    //
    const selectPage = (pageSelector) => {
        let content = (<></>);
        switch (pageSelector) {
            case RECENT_READINGS:
                console.log('selected page: ', RECENT_READINGS);
                content = (<><RecentReadings Alexa={Alexa} userID={userID} /></>);
                break;
            case WEEKLY_READINGS:
                console.log('selected page: ', WEEKLY_READINGS);
                content = (<><WeeklyReadings Alexa={Alexa} userID={userID} /> </>);
                break;
            case CALENDAR:
                console.log('selected page: ', CALENDAR);
                content = (<><CalendarSelector /></>);
                break;
            default:
                console.log('selected page: ', 'default');
                content = (<></>);
                break;
        }
        return content;
    }
    return (
        <>
            <div className={`aurora-bg blue ${menu_switcher ? 'active' : ''}`}>
                <SideBar />
                <div className="col-main">
                    {selectPage(currentPage)}
                </div>
                <div className="col-right">
                    <Menu />
                </div>
            </div>
            <Splash />
            {/* <div id="debug" style={{ display: `${console_switcher ? 'block' : 'none'}`, left: '200px', padding: 10, color: 'black', background: 'white', position: 'fixed', height: 500, width: 300, overflow: 'scroll' }}>
                ***Console: {JSON.stringify(consoleAlexa)}***
            </div> */}
        </>
    );
}
const mapStateToProps = state => ({
    console_switcher: state.console_switcher,
    currentPage: state.pageSelector.currentPage,
    menu_switcher: state.menu_switcher,
    RECENT_READINGS: state.pageSelector.RECENT_READINGS,
    WEEKLY_READINGS: state.pageSelector.WEEKLY_READINGS,
    CALENDAR: state.pageSelector.CALENDAR,
})
const mapDispatchToProps = (dispatch) => {
    return {
        dateSelector: (newTargetDate) => { dispatch(dateSelector(newTargetDate)) },
        pageSelector: (newPage) => { dispatch(pageSelector(newPage)) },
        alertBox: (value) => { dispatch(alertBox(value)) },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Index);

