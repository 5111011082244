import React from "react";
import { switchMenu } from "../../store/actions/switchMenu";
import { connect } from 'react-redux';
//
import { pageSelector } from '../../store/actions/pageSelector';
//
function Menu({ pageSelector, menu_switcher, switchMenu, RECENT_READINGS, WEEKLY_READINGS, CALENDAR }) {
    function switchPage(page) {
        pageSelector(page)
        switchMenu();
    }
    //
    return (
        <>
            <button className={`menu-toggle ${menu_switcher ? ' active' : ''}`} onClick={switchMenu}>
                <span></span>
                <span></span>
                <span></span>
            </button>
            <ul className={`menu-list ${menu_switcher ? ' open' : ''}`}>
                <li className="menu-title">
                    <p>Escolha o gráfico:</p>
                </li>
                <li onClick={() => { switchPage(RECENT_READINGS) }}>
                    <p>Diário</p>
                </li>
                <li onClick={() => { switchPage(WEEKLY_READINGS) }}>
                    <p>Semanal</p>
                </li>
            </ul>
        </>
    );
}
const mapStateToProps = state => ({
    state: state,
    menu_switcher: state.menu_switcher,
    RECENT_READINGS: state.pageSelector.RECENT_READINGS,
    WEEKLY_READINGS: state.pageSelector.WEEKLY_READINGS,
    CALENDAR: state.pageSelector.CALENDAR,
})
const mapDispatchToProps = (dispatch) => {
    return {
        switchMenu: (state) => { dispatch(switchMenu(state)) },
        pageSelector: (newPage) => { dispatch(pageSelector(newPage)) }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Menu);
