
const thisInitialState = {
    text: '',
    icon: ''
}

export function alertBoxReducer(state = thisInitialState, action) {
    switch (action.type) {
        case 'SET_EVALUATION':
            let text = action.text;
            let icon = action.icon;
            return {
                text: text,
                icon: icon
            };
        default:
            return state;
    }
}