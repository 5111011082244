import ReactGA from 'react-ga';

function initializeGA(page="/"){
    ReactGA.initialize('UA-179713378-1');
    ReactGA.pageview(page);
}
function eventGA(category, action){
    ReactGA.event({
        category: category,
        action: action
    });
}

export { initializeGA, eventGA }