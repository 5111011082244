import React, { useState } from "react";
import { connect } from 'react-redux';
//COMPONENTS
import Calendar from 'react-calendar';
//IMAGES
import { pageSelector } from '../store/actions/pageSelector';
import { dateSelector } from '../store/actions/dateSelector';
//
function CalendarSelector({ pageSelector, WEEKLY_READINGS, dateSelector, targetDate, weekStart, weekEnd }) {
    const [selectedDate, setSelectedDate] = useState(targetDate);
    console.warn('render page: ', 'calendar');
    function confirmDate() {
        if (selectedDate.getDate() !== targetDate.getDate()) {
            dateSelector(selectedDate);
            pageSelector(WEEKLY_READINGS);
        }
    }
    //
    return (
        <>
            <div className="daily-details-chart calendar">
                    <Calendar minDate={new Date('2021-7-1')} maxDate={new Date()} prev2Label={null} next2Label={null} onChange={(date) => setSelectedDate(date)} />
                </div>
                <div className="daily-overview-chart calendar">
                    <div className="calendar-button" onClick={() => pageSelector(WEEKLY_READINGS)}>
                        <p className="text">cancelar</p>
                    </div>
                    <div className="calendar-button" onClick={confirmDate}>
                        <p className="text">confirmar</p>
                    </div>
                </div>
        </>
    );
}
const mapStateToProps = state => ({
    targetDate: state.dateSelector.targetDate,
    weekStart: state.dateSelector.weekStart,
    weekEnd: state.dateSelector.weekEnd,
    WEEKLY_READINGS: state.pageSelector.WEEKLY_READINGS,
})
const mapDispatchToProps = (dispatch) => {
    return {
        dateSelector: (newTargetDate) => { dispatch(dateSelector(newTargetDate)) },
        pageSelector: (newPage) => { dispatch(pageSelector(newPage)) }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CalendarSelector);
