import firebase from 'firebase';
import moment from 'moment';
//
// FIREBASE QUERIES
const firebaseFindUser = (userID) => {
    return firebase.firestore()
    .collection('lastconn')
    .doc(userID)
    .get();
}
const firebaseDateRange = (startDate, endDate, userID, collection="readings", order = "asc") => {
    return firebase.firestore()
        .collection(collection)
        .where('userID', '==', userID)
        .orderBy("date", order)
        .where("date", "<=", endDate)
        .where("date", ">=", startDate);
}
const firebaseSaveEvent = ({ request, type, description, userID }) => {
    return firebase.firestore().collection("events")
        .doc()
        .set({
            request: request,
            type: type,
            description: description,
            date: moment(new Date()).valueOf(),
            userID: userID,
        });
}
export { firebaseDateRange, firebaseFindUser, firebaseSaveEvent }