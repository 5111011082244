import React from "react";
import { connect } from 'react-redux';
import { Image } from "react-bootstrap";
//COMPONENTS
import Logo from '../logo';
//
function SideBar({ alert_box }) {
    //
    return (
        <>
            <div className="col-left">
                <Logo />
                <div className="legenda">
                    <span className="" >
                        acima de 180
                    </span>
                    <span className="" >
                        no alvo
                    </span>
                    <span className="" >
                        abaixo de 70
                    </span>
                </div>
                <div className="alert-box">
                    {alert_box.icon === "" ? <Image src={require(`../../assets/images/icon_good.svg`).default} /> : <Image src={require(`../../assets/images/${ alert_box.icon }.svg`).default} />}
                    <p className="alert-message">{alert_box.text}</p>
                </div>
            </div>
        </>
    );
}
const mapStateToProps = state => ({
    alert_box: state.alert_box,
})
const mapDispatchToProps = (dispatch) => {
    return {
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
