import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
//Redux
import { applyMiddleware, createStore } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
//FIREBASE
import firebaseConfig from './utils/firebaseConfig';
import firebase from 'firebase/app'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { createFirestoreInstance } from 'redux-firestore'
//STATES
import initialState from './store/initialState';
import root from './store/reducers/root';
//CSS
import './App.scss';
//
//
const rrfConfig = {
  userProfile: 'users'
}
firebase.initializeApp(firebaseConfig);
firebase.firestore();
const middlewares = [thunk];
const store = createStore(root, initialState, applyMiddleware(...middlewares));

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance
}
//
ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <App />
    </ReactReduxFirebaseProvider>
  </Provider>
  ,
  document.getElementById('root')
);