
const RECENT_READINGS = 'recent_readings';
const WEEKLY_READINGS = 'weekly_readings';
const CALENDAR = 'calendar';
const thisInitialState = {
    currentPage: '',
    RECENT_READINGS: RECENT_READINGS,
    WEEKLY_READINGS: WEEKLY_READINGS,
    CALENDAR: CALENDAR,
}

export function pageSelectorReducer(state = thisInitialState, action) {
    switch (action.type) {
        case 'SELECT_PAGE':
            state.currentPage = action.currentPage;
            return state;
        default:
            return state;
    }
}