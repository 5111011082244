import React from 'react';
import Helmet from 'react-helmet';
//
export default function Page({ component: Component, title, label }){
    //Esse componente retorna uma página montada com seu Título na Aba do Navegador.
    //A lib Helmet modifica o metatag title da página, anterando assim o título da aba.
    //O Container recebe o conteúdo da página.
    return class Page extends React.Component {
        render() {
            return (
                <>
                    <Helmet>
                        <title>{label + title}</title>
                        <meta name="viewport" content="width=960"></meta>
                    </Helmet>
                    <Component pageTitle={title} {...this.props}/>
                </>
            );
        }
    };
};