import React from "react";
import * as d3 from 'd3';
import { connect } from 'react-redux';
import { isLoaded } from "react-redux-firebase";
//
import { dateSelector } from '../store/actions/dateSelector';
import { pageSelector } from '../store/actions/pageSelector';
import { switchRecentReadings } from '../store/actions/switchRecentReadings';
//COMPONENTS
import { WeeklyStrip } from "../components/chart";
//IMAGES
import calendarSvg from '../assets/images/calendar_date_icon.svg';
import arrowSvg from '../assets/images/arrow.svg';
import { Image } from "react-bootstrap";
//
//CONST
const weekLabels = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];
//
function WeeklyReadings({ switchRecentReadings, Alexa, userID, weeklyReadings, pageSelector, CALENDAR, dateSelector, targetDate, weekStart, weekEnd }) {
    console.warn('render page: ', 'weekly_readings');
    switchRecentReadings(true);
    //
    function weekDown() {
        console.warn('weekly_readings weekDown: ', 'executed');
        console.log('weekDown targetDate: ', targetDate);
        let newDate = new Date(targetDate)
        newDate.setDate(targetDate.getDate() - 7);
        dateSelector(newDate);
    }
    function weekUp() {
        console.warn('weekly_readings weekUp: ', 'executed');
        console.log('weekUp targetDate: ', targetDate);
        let newDate = new Date(targetDate)
        newDate.setDate(targetDate.getDate() + 7);
        dateSelector(newDate);
    }
    //
    //let hipoEventsCounter;
    let abovePercent, belowPercent;
    let meanTotal;
    let timePassedAbove;
    let timePassedBelow;
    if (isLoaded(weeklyReadings)) {
        // filtre o array de dados somente para valores acima de 150
        const above = d3.filter(weeklyReadings, function (d) {
            return d.sgv >= 150;
        });
        const below = d3.filter(weeklyReadings, function (d) {
            return d.sgv <= 70;
        });
        //hipoEventsCounter = below.length;
        // porcentagem de todos os items acima de 150
        abovePercent = ((above.length / weeklyReadings.length) * 100).toFixed(1);
        // porcentagem de todos os items abaixo de 70
        belowPercent = ((below.length / weeklyReadings.length) * 100).toFixed(1);
        // média das glicemias da array
        meanTotal = Math.round(d3.mean(weeklyReadings, function (d) {
            return d.sgv;
        }));
        // soma do tempo passado acima de 150 em milissegundos
        // aqui estamos  considerando que cada leitura ficou igual por 5 minutos. 
        // Tudo bem, é uma estimativa
        const sumAbove = above.length * 5;
        const sumBelow = below.length * 5;
        timePassedAbove = Math.floor(sumAbove / 60) + "h" + ((sumAbove - (Math.floor(sumAbove / 60) * 60)) <= 0 ? '' : (sumAbove - (Math.floor(sumAbove / 60) * 60)));
        timePassedBelow = Math.floor(sumBelow / 60) + "h" + ((sumBelow - (Math.floor(sumBelow / 60) * 60)) <= 0 ? '' : (sumBelow - (Math.floor(sumBelow / 60) * 60)));
    }
    //
    return (
        <>
            <>
                <div className="page-title">
                    <h2>
                        {`Semana de ` + weekStart.toLocaleDateString('pt-BR') + ` a ` + weekEnd.toLocaleDateString('pt-BR')}
                    </h2>
                    <div className="calendar-pagination">
                        <Image className="left" src={arrowSvg} onClick={weekDown} />
                        <Image className="calendar-icon" src={calendarSvg} onClick={() => pageSelector(CALENDAR)} />
                        <Image className="right" src={arrowSvg} onClick={weekUp} />
                    </div>
                </div>
                <div className="daily-details-chart">
                    <WeeklyStrip userID={userID} Alexa={Alexa} weekLabels={weekLabels} />
                </div>
                <div className="daily-overview-chart weekly">
                    <div className="aurora-card">
                        <p className="card-title">hiperglicemia</p>
                        <p className="indicator pink">{abovePercent !== 'NaN' ? abovePercent : '--'}%</p>
                        <p className="card-subtitle">{timePassedAbove}</p>
                    </div>
                    <div className="aurora-card">
                        <p className="card-title">glicemia média</p>
                        <p className="indicator">{meanTotal || '--'}</p>
                        <p className="card-subtitle">mg/dL</p>
                    </div>
                    <div className="aurora-card">
                        <p className="card-title">hipoglicemia</p>
                        <p className="indicator yellow">{belowPercent !== 'NaN' ? belowPercent : '--'}%</p>
                        <p className="card-subtitle">{timePassedBelow}</p>
                    </div>
                </div>
            </>
        </>
    );
}
const mapStateToProps = state => ({
    weeklyReadings: state.firestore.ordered.weeklyReadings,
    targetDate: state.dateSelector.targetDate,
    weekStart: state.dateSelector.weekStart,
    weekEnd: state.dateSelector.weekEnd,
    CALENDAR: state.pageSelector.CALENDAR,
})
const mapDispatchToProps = (dispatch) => {
    return {
        dateSelector: (newTargetDate) => { dispatch(dateSelector(newTargetDate)) },
        pageSelector: (newPage) => { dispatch(pageSelector(newPage)) },
        switchRecentReadings: (value) => { dispatch(switchRecentReadings(value)) },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(WeeklyReadings);
