import React, { useEffect, useRef } from "react";
import * as d3 from 'd3';
import moment from "moment";
//REDUX
import { connect } from 'react-redux';
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
//COMPONENTS
import LoadChart from '../loadChart';
//ICONS
import OrangeIcon from '../../assets/images/orange.svg';
import ExerciseIcon from '../../assets/images/exercise.svg';
//
function DayStripDotted({ events, dayReadings }) {
    const d3Chart = useRef();
    const chartContainerRef = useRef();
    console.warn('render chart: ', 'daily-overview-dotted');
    console.log('daily-overview-dotted', dayReadings);

    // color scale
    const glucoseColorRange = d3.scaleLinear()
        .domain([
            0, 55, 68,
            72, 120, 150,
            160, 180, 250, 1000
        ])
        .range([
            '#F9C307', '#F9C307', '#B6E82A',
            '#A5E0E2', '#2FB3BE', '#098B9D',
            '#C84DCB', '#E04445', '#E04445', '#9B1011',
        ]);
    //
    // touch / mouse over 
    const handleMouseOver = (d, p) => {
        /*
        d3.select("#recent-dotted-chart").insert("div", ":first-child")
            .attr("id", "chartTooltipRect")
            .attr("class", "shadow")
            .style("left", (d.pageX - 29) + "px")
            .style('background-color', `${glucoseColorRange(p.sgv)}`)
            .style('color', `${glucoseColorRange(p.sgv)}`)
            .append('span').text(p.sgv);
        */
    }
    const handleMouseOut = (d) => {
        //d3.select("#recent-dotted-chart").selectAll("#chartTooltipRect").remove();
    }
    const drawChart = () => {
        console.warn('daily-overview-dotted drawChart: ', 'executed');
        const dataReadings = dayReadings || [];
        const eventReadings = events || [];
        const today = new Date();
        const yesterday = new Date(today.getTime());
        yesterday.setDate(yesterday.getDate()-1);
        let minMaxDate = [yesterday.getTime(),today.getTime()];
        let minMaxSgv = d3.extent(dataReadings, d => d.sgv);
        minMaxSgv[0] = minMaxSgv[0] > 50 ? 50 : minMaxSgv[0];
        minMaxSgv[1] = minMaxSgv[1] < 170 ? 170 : minMaxSgv[1];
        //
        if (dataReadings) {
            d3.select(d3Chart.current).selectAll("*").remove();
            //CREATE CHART WITH DATA
            const margin = { top: 25, right: 0, bottom: 40, left: 0 };
            const spaceRight = 35;
            const width = chartContainerRef.current ? (2*d3.select(chartContainerRef.current).node().offsetWidth) - margin.left - margin.right : 0;
            const height = chartContainerRef.current ? d3.select(chartContainerRef.current).node().offsetHeight: 0;

            //
            //x axis scale
            const x = d3.scaleTime()
                .range([0, width-spaceRight])
                .domain([new Date(minMaxDate[0]), new Date(minMaxDate[1])])
            const xAxis = d3.axisBottom(x).ticks(d3.timeHour.every(1)).tickFormat(d3.timeFormat("%Hh"));
            //.tickFormat(d => `${new Date(parseInt(d)).getHours()}h${new Date(parseInt(d)).getMinutes() >= 10 ? new Date(parseInt(d)).getMinutes() : '0' + new Date(parseInt(d)).getMinutes()}`)
            
            const svg = d3.select(d3Chart.current);
            svg.attr('width', width)
                .attr('height', height)
                .append('g')
                .attr("id", "#svg-content")
                .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');
            
            // escala para eixo das horas (x)
            svg.append('g')
                .attr("class", "axisText axisX")
                .attr('transform', 'translate(0,' + (height - margin.bottom) + ')')
                .call(xAxis);
            
            // escala para eixo da glicemia (y)
            const y = d3.scaleLinear()
                .range([height-margin.bottom, margin.top])
                .domain(minMaxSgv);

            let hTickValues = [70, 150, 180, 250];
            for(var i=2; i>0; i--){
                if(minMaxSgv[1] < hTickValues[hTickValues.length-1]){
                    hTickValues = hTickValues.slice(0,-1);
                }
            }

            //CHART BG
            svg.append("rect")
                .attr("class", "chartBgRect bg")
                .attr('height', height - margin.bottom - margin.top)
                .attr('width', width)
                .attr('y', y(minMaxSgv[1]))
                .attr('x', 0)
                .attr('rx', 2)
                .attr('ry', 2)
                .style("fill", '#E3E2D6')
                .style("opacity", 0.4)
            // inclui faixa dos 70 aos 150
            svg.append("rect")
                .attr("class", "chartBgRect middle")
                .attr('height', (d) => {
                    return Math.abs(y(150) - y(70));
                })
                .attr('width', width - spaceRight)
                .attr('y', y(150))
                .attr('x', 0)
                .style("fill", 'rgba(255,255,255,0.35)')
                svg.append('g')
                .attr("class", "axisText axisY")
                .call(d3.axisLeft(y).tickValues(hTickValues));
            
            // linhas de marcação da faixa no alvo
            svg.selectAll("line").each(function () {
                d3.select(this)
                    .attr("x1", width)
                    .attr("x2", 0)
                    .attr("stroke", '#E3E2D6')
                    .style('transform', 'translate(-36px, 0px)')

                // caixas de valor da faixa no alvo
                svg.selectAll(".axisY .tick").each(function () {
                    d3.select(this)
                        .insert("rect", ":first-child")
                        .attr('rx', '3')
                        .attr('ry', '4')
                        .attr('width', 30)
                        .attr('height', 22)
                        .attr('transform', 'translate('+(width-31)+',-11)')
                        .attr('fill', '#FFFFFF')
                        .style('display', (d) => {
                            if(d===180){return 'none';}
                            return 'none';
                        })
                        .attr('stroke', '#E3E2D6');
                    //align text
                    d3.select(this)
                        .select('text')
                        .attr('text-anchor', 'middle')
                        .attr('transform', 'translate('+(width - 5)+',0)');
                });
            });
            //
            //xAxis Grid
            const line = xAxis.tickSize(-height + (margin.top + margin.bottom)).tickFormat("");
            svg.append("g")
                .attr("class", "grid")
                .attr("transform", "translate(0," + (height - margin.bottom) + ")")
                .call(line);
            //
            //Create SVG CHART
            svg.selectAll()
                .data(dataReadings)
                .enter()
                .append("circle")
                .attr("class", "chartDot")
                .attr("cx", function (d) { return x(d.date) })
                .attr("cy", function (d) { return y(d.sgv) })
                .attr("r", 2)
                .style("fill", function (d) { return glucoseColorRange(d.sgv) })
                .on("mouseover", handleMouseOver)
                .on("mouseout", handleMouseOut);
            //
            //Refeicao Icons
            if (eventReadings.length > 0) {
                // event x scale
                let eX = d3.scaleLinear()
                    .range([0, width])
                    .domain([d3.min(dataReadings, (d) => d.date), d3.max(dataReadings, (d) => d.date)]);
                d3.axisBottom(eX);
                //dotted line
                svg.selectAll()
                    .data(eventReadings)
                    .enter()
                    .append('line')
                    .attr("x1", function (d) { return eX(d.date) })
                    .attr("x2", function (d) { return eX(d.date) })
                    .attr("y1", "10")
                    .attr("y2", height)
                    .attr("stroke-dasharray", "5, 5")
                    .style("fill", '#e3e2d6')
                    .style("display", function (d) { return `${eX(d.date) !== undefined ? 'block' : 'none'}` });
                //images
                svg.selectAll()
                    .data(eventReadings)
                    .enter()
                    .append("image")
                    .attr("xlink:href", d => { return d.type === 'meal' ? OrangeIcon : ExerciseIcon })
                    .attr("class", "chartIcon")
                    .attr("x", function (d) { return x(d.date) - 10 })
                    .attr("y", "18")
                    .style("display", function (d) { return `${x(d.date) !== undefined ? 'block' : 'none'}` })
            }
            if(chartContainerRef.current){ chartContainerRef.current.scrollLeft = width; } 
        }
    }

    useEffect(() => {
        //if(dayReadings){console.log("dados recebidos: ", dayReadings)};
        drawChart();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [events, dayReadings]);
    //
    //
    return (
        <>
            <LoadChart data={dayReadings} onChange={drawChart}>
                <div className="chart-row">
                    <div ref={ chartContainerRef } id='recent-dotted-chart' className="chart-container">
                        <svg ref={d3Chart}></svg>
                    </div>
                </div>
            </LoadChart>
        </>
    );
}
const mapStateToProps = state => ({
    events: state.firestore.ordered.events,
    dayReadings: state.firestore.ordered.dayReadings,
})
const mapDispatchToProps = (dispatch) => {
    return {
    }
}
export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    firestoreConnect(props => {
        const id = props.userID;
        console.warn('daily-overview-dotted firestoreConnect: ', 'executed');
        return [
            {
                collection: 'events',
                where: [
                    ['userID', '==', id],
                    ["date", ">=", moment(props.startDate).valueOf()]
                ],
                orderBy: ['date', 'asc'],
                storeAs: 'events',
            },
            {
                collection: 'readings',
                where: [
                    ['userID', '==', id],
                    ["date", ">=", moment(props.startDate).valueOf()]
                ],
                orderBy: ['date', 'asc'],
                storeAs: 'dayReadings',
            }
        ];
    })
)(DayStripDotted);