import React from "react";
import { connect } from "react-redux";
import { isLoaded } from "react-redux-firebase";
//COMPONENTS
import { DayStripBar, DayStripDotted } from "../components/chart";
import Splash from './splash';
//
function RecentReadings({ Alexa, userID, dayReadings, events }) {
    //const endDate = new Date(new Date().toUTCString());
    //const startDate = new Date(endDate.getUTCFullYear(), endDate.getUTCMonth(), endDate.getUTCDate() - 1, endDate.getUTCHours(), endDate.getUTCMinutes(), endDate.getUTCSeconds());
    const endDate = new Date();
    const startDate = new Date(endDate.getTime());
    startDate.setDate(startDate.getDate() - 1);
    console.warn('render page: ', 'recent_readings');
    //
    return (
        <>
            <div className="page-title">
                <h2>Leituras de glicose das últimas 24 horas, a cada 5 minutos</h2>
            </div>
            <div className="daily-details-chart">
                <DayStripBar Alexa={Alexa} userID={userID} startDate={startDate} endDate={endDate} />
            </div>
            <div className="daily-overview-chart">
                <DayStripDotted userID={userID} startDate={startDate} endDate={endDate} />
            </div>
            {
                isLoaded(events) && isLoaded(dayReadings) ? <></> : <Splash isALoader={true} />
            }
        </>
    );
}
const mapStateToProps = state => ({
    events: state.firestore.ordered.events,
    dayReadings: state.firestore.ordered.dayReadings,
})
const mapDispatchToProps = (dispatch) => {
    return {
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(RecentReadings);
