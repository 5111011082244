import React,{useEffect,useState} from "react";
import { connect } from "react-redux";
import { switchConsole } from "../store/actions/switchConsole";
import ReactLoading from 'react-loading';
//
//
function Splash() {
    const [isOpen,setIsOpen] = useState(true);
    //
    useEffect(() => {
        //
        let myInterval = setInterval(() => {
            setIsOpen(false);
        }, 3000);
        return () => {
            clearInterval(myInterval);
        };
    });
    return (
        <>
            <div className="splash blue" style={{display:`${isOpen?'grid':'none'}`}}>
                <div style={{
                    gridColumn: '2/3',
                    gridRow: '2/2',
                }}>
                    <ReactLoading type='spin' color='#FFFFFF' />
                </div>
            </div>
        </>
    );
}

const mapStateToProps = state => ({
    logo: state.logo,
    state: state,
})
const mapDispatchToProps = (dispatch) => {
    return {
        switchConsole: (value) => dispatch(switchConsole())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Splash);