import React, { useEffect, useRef } from "react";
import * as d3 from 'd3';
import moment from "moment";
//REDUX
import { connect } from 'react-redux';
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { switchRecentReadings } from '../../store/actions/switchRecentReadings';
//COMPONENTS
import LoadChart from '../loadChart';
//ICONS
import OrangeIcon from '../../assets/images/orange.svg';
import Exercise from '../../assets/images/exercise.svg';
//
function DayStrip({ Alexa, userID, events, dayReadings, switchRecentReadings, switch_recent_readings }) {
    console.warn('render chart: ', 'recent-readings-bars');
    console.log('recent-readings-bars', dayReadings);
    //
    const d3Chart = useRef();
    const chartHolderRef = useRef();
    const toolTip = useRef();
    const scrollPosition = useRef();
    //
    function drawChart() {
        console.warn('recent-readings-bars drawChart: ', 'executed');
        const min = 5;
        const msInDay = 24 * 60 * 60 * 1000;
        const msPerBar = min * 60 * 1000;
        let dataReadings = dayReadings || [];
        dataReadings = dataReadings.slice().sort(function (a, b) {
            let sortNum = (b.date > a.date) ? 1 : -1;
            return sortNum;
        });
        //const dataSliced = dataReadings.slice(1, -1);
        const eventReadings = events || [];
        const today = new Date();
        today.setMinutes(min * Math.ceil(today.getMinutes() / min), 0, 0)
        const yesterday = new Date(today.getTime());
        yesterday.setDate(yesterday.getDate() - 1);
        let minMax = [yesterday.getTime(), today.getTime()];
        let groupedBy5Min = d3.rollup(dataReadings,
            v => {
                let obj = {};
                obj.totalReadings = v.length;
                obj.averageSgv = Math.round(d3.mean(v, d => d.sgv));
                obj.readings = v.slice().sort(function (a, b) {
                    let sortNum = (b.sgv > a.sgv) ? 1 : -1;
                    return sortNum;
                });

                return obj;
            },
            d => {
                let date = new Date(d.date);
                date.setMinutes(min * Math.floor(date.getMinutes() / min), 0, 0);
                return date.getTime();
            }
        );
        // CREATE CHART WITH DATA
        const margin = { top: 0, right: 0, bottom: 50, left: 0 };
        const lastReadingSize = { w: 150, h: 140 };
        const spaceBetween = 2;
        const visibleWidth = chartHolderRef.current ? chartHolderRef.current.offsetWidth - lastReadingSize.w : 0;
        const visibleBars = 36;
        const barWidth = (visibleWidth / visibleBars);
        const height = 140;

        // color scale
        const glucoseColorRange = d3.scaleLinear()
            .domain([
                0, 55, 68,
                72, 120, 150,
                160, 180, 250, 1000
            ])
            .range([
                '#F9C307', '#F9C307', '#B6E82A',
                '#A5E0E2', '#2FB3BE', '#098B9D',
                '#C84DCB', '#E04445', '#E04445', '#9B1011',
            ]);
        function handleMouseOver(d, p) {
            let target = d3.select(d.target);
            let isLast = target.node() ? target.node().classList.contains('last-cell') : false;
            let lastReading = isLast ? dataReadings[0].sgv : 0
            let sgv = target && target.data()[0] && !isLast ? target.data()[0].data.averageSgv : lastReading;

            d3.select(toolTip.current)
                .attr("class", 'visible shadow')
                .style("left", (d.pageX - 25) + "px")
                .style('background-color', `${sgv > 0 ? glucoseColorRange(sgv) : '#BABBA7'}`)
                .style('color', `${sgv > 0 ? glucoseColorRange(sgv) : '#BABBA7'}`)

            d3.select(toolTip.current).select('#tooltipText').text(`${sgv > 0 ? sgv : '-'}`);
        }

        function handleMouseOut() {
            d3.select(toolTip.current)
                .attr("class", '');
        }

        let width = visibleWidth * 10;
        let cellTime = new Date(minMax[0]);
        let cellsInDay = [];
        let numCells = msInDay / msPerBar;//Math.ceil((minMax[1] - minMax[0])/msPerBar);

        for (var i = 0; i < numCells; i++) {
            cellsInDay.push({ date: cellTime.getTime(), data: groupedBy5Min.get(cellTime.getTime()) || {} });
            cellTime.setMinutes(cellTime.getMinutes() + min);
        }

        // Set up chart
        //d3.select(d3Chart.current).selectAll("*").remove();
        const svg = d3.select(d3Chart.current);
        svg.selectAll('*').remove();
        svg.append('g')
            .attr("id", "#svg-content")
            .attr('transform', 'translate(0,0)');
        //
        // CHART DATA
        if (dataReadings) {
            width = dataReadings ? (barWidth * numCells) + lastReadingSize.w : visibleWidth * 10;
            scrollPosition.current = width;
            //console.log("scroll",scrollPosition.current)

            if (!svg) { return; }

            svg.attr('width', width + margin.left)
                .attr('height', height + margin.top + margin.bottom)

            let x = d3.scaleBand()
                .range([0, width - lastReadingSize.w])
                .domain(cellsInDay.slice(0, -1).map((d) => { return new Date(d.date).getTime() }))

            let xAxis = d3.axisBottom(x).tickFormat(d => {
                let date = new Date(parseInt(d));
                return `${date.getHours()}h${date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes()}`
            })

            svg.append("g")
                .attr("class", "axisText axisX")
                .attr("transform", "translate(-1," + (height + 25) + ")")
                .call(xAxis);
            // adicionando último tick de hora manualmente
            svg.append("text")
                .style('fill', '#BABBA7')
                .attr("class", "axisText axisX last")
                .attr("text-anchor", "middle")
                .attr("alignment-baseline", "middle")
                .attr("font-size", "0.8rem")
                .attr("transform", (d) => {
                    let yPos = 40 + lastReadingSize.h;
                    let xPos = width - (lastReadingSize.w / 2)
                    return "translate(" + xPos + "," + yPos + ")";
                })
                .text((d) => {
                    let lastReading = dataReadings[0];
                    let date = lastReading ? new Date(lastReading.date) : '-';
                    date.toLocaleString('pt-BR', { timezone: 'America/Sao_Paulo' });
                    let dateStr = lastReading ? `${date.getHours()}h${date.getMinutes() >= 10 ? date.getMinutes() : '0' + date.getMinutes()}` : ''
                    return dateStr;
                })
            //
            //lines
            svg.selectAll("line").each(function () {
                d3.select(this)
                    .attr("y1", "5")
                    .attr("y2", -(height))
            });

            svg.on("mouseover", handleMouseOver)
                .on("mousemove", handleMouseOver)
                .on("mouseout", handleMouseOut);
            //
            // Create SVG CHART
            let barGroup = svg.selectAll(".chartBar")
                .data(cellsInDay)
                .enter()
                .append("g")
                .attr("transform", (d, i) => {
                    let date = new Date(d.date);
                    let xPos = x(date.getTime());
                    return (i === cellsInDay.length - 1) ? "translate(" + (width - lastReadingSize.w) + ",20)" : "translate(" + xPos + ",20)";
                })
                .attr("class", (d, i) => {
                    return (i === cellsInDay.length - 1) ? "chartBar last" : "chartBar"
                });
            barGroup.append("rect")
                .attr("height", height)
                .attr("width", function (d, i) {
                    let w = (i === cellsInDay.length - 1) ? lastReadingSize.w : barWidth;
                    return w;
                })
                .attr("stroke-width", spaceBetween)
                .attr("stroke-opacity", 1)
                .attr("stroke", '#f5f6f1')
                .attr("rx", 4)
                .attr("ry", 4)
                .style("fill", (d, i) => {
                    let color = '#E0E0DB';
                    let cellObj = i < cellsInDay.length - 1 ? groupedBy5Min.get(d.date) : null;
                    if (cellObj) {
                        color = glucoseColorRange(cellObj.averageSgv);
                    } else if (i == cellsInDay.length - 1) {
                        let lastReading = dataReadings[0];
                        let sgv = lastReading ? lastReading.sgv : '';
                        color = lastReading ? glucoseColorRange(sgv) : '#E0E0DB';
                    }
                    return color;
                })
                .attr("class", (d, i) => {
                    return i === cellsInDay.length - 1 ? 'last-cell' : '';
                })
            /*
            .on("mouseover", handleMouseOver)
            .on("mousemove", handleMouseOver)
            .on("mouseout", handleMouseOut);
            */
            barGroup.filter((d, i) => { return (i === cellsInDay.length - 1) })
                .append("text")
                .attr("class", "last-reading")
                .attr("text-anchor", "middle")
                .attr("alignment-baseline", "middle")
                .attr("font-size", "52px")
                .attr("transform", () => {
                    let x = lastReadingSize.w / 2;
                    let y = 5 + (lastReadingSize.h / 2);
                    return "translate(" + x + "," + y + ")";
                })
                .text((d) => {
                    let lastReading = dataReadings[0];
                    let sgv = lastReading ? lastReading.sgv : '-';
                    return sgv;
                })

            if (chartHolderRef.current) { chartHolderRef.current.scrollLeft = scrollPosition.current; }
        }
        //
        //Refeicao Icons
        // event x scale
        if (eventReadings.length > 0) {
            const dataSliced = dataReadings.slice(1, -1);
            let eX = d3.scaleLinear()
                .range([0, width - lastReadingSize.w])
                .domain([d3.min(dataSliced, (d) => d.date), d3.max(dataSliced, (d) => d.date)]);
            d3.axisBottom(eX);
            //
            svg.selectAll()
                .data(eventReadings)
                .enter()
                .append("image")
                .attr("xlink:href", d => { return d.type === 'meal' ? OrangeIcon : Exercise })
                .attr("class", "chartIcon")
                .attr("x", function (d) { return eX(d.date) })
                .attr("y", "20")
                .attr("rx", 2)
                .attr("ry", 2)
                .style("fill", '#000')
                .style("display", function (d) { return `${eX(d.date) !== undefined ? 'block' : 'none'}` });
        }
    }
    const handleOnScroll = () => {
        scrollPosition.current = chartHolderRef.current.scrollLeft;
        d3.select(toolTip.current)
            .attr("class", '');
    }
    //
    //send dayly readings useEffect
    useEffect(() => {
        if (dayReadings) {
            dayReadings.slice().sort((a, b) => { return b.sgv - a.sgv; });
            if (Alexa && switch_recent_readings === false) {
                let newArray = dayReadings.map((item) => {
                    return {
                        sgv: item.sgv,
                        date: item.date,
                        direction: item.direction,
                    }
                })
                newArray = newArray.sort((a, b) => { return b.sgv - a.sgv; });
                let obj = {
                    request_type: 'recent_readings',
                    user: userID,
                    readings: newArray,
                    events: events,
                };
                Alexa.skill.sendMessage({ ...obj }, (r) => {
                    console.log('recent_readings sendToAlexa');
                });
            }
        } if (switch_recent_readings === null) {
            switchRecentReadings(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dayReadings]);
    //
    //Draw Chart useEffect
    useEffect(() => {
        drawChart();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [events, dayReadings]);
    //
    return (
        <>
            <LoadChart data={dayReadings} trigger={dayReadings} onChange={drawChart}>
                <div className="chart-row">
                    <div ref={chartHolderRef} className="readings-bar-chart" id='recentBarChart' onScroll={handleOnScroll}>
                        <svg ref={d3Chart}></svg>
                        <div ref={toolTip} id="chartTooltipRect">
                            <span id="tooltipText"></span>
                        </div>
                    </div>
                </div>
            </LoadChart>
        </>
    );
}
const mapStateToProps = state => ({
    console_switcher: state.console_switcher,
    events: state.firestore.ordered.events,
    dayReadings: state.firestore.ordered.dayReadings,
    switch_recent_readings: state.switch_recent_readings,
})
const mapDispatchToProps = (dispatch) => {
    return {
        switchRecentReadings: (value) => { dispatch(switchRecentReadings(value)) },
    }
}
export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    firestoreConnect(({ userID, startDate, switchRecentReadings, switch_recent_readings }) => {
        const id = userID;
        console.warn('recent-readings-bars firestoreConnect: ', 'executed');
        //
        if (switch_recent_readings)
            switchRecentReadings(null);
        return [
            {
                collection: 'events',
                where: [
                    ['userID', '==', id],
                    ["date", ">=", moment(startDate).valueOf()]
                ],
                orderBy: ['date', 'asc'],
                storeAs: 'events',
            },
            {
                collection: 'readings',
                where: [
                    ['userID', '==', id],
                    ["date", ">=", moment(startDate).valueOf()]
                ],
                orderBy: ['date', 'asc'],
                storeAs: 'dayReadings',
            }
        ];
    })
)(DayStrip);
